<div class="col">
    <ul>
        <li>
            <a href="pages/info">
                <img src="../../../assets/icone/info-removebg-preview.png" alt="Icona 1">
                <p>INFO</p>
            </a>
        </li>
        <li>
            <a href="pages/chi-siamo">
                <img src="../../../assets/icone/chisiamo-removebg-preview.png" alt="Icona 2">
                <p>CHI SIAMO</p>
            </a>
        </li>
        <li>
            <a href="pages/servizi">
                <img src="../../../assets/icone/servizi-removebg-preview.png" alt="Icona 3">
                <p>SERVIZI</p>
            </a>
        </li>
        <li>
            <a href="pages/offerte">
                <img src="../../../assets/icone/offerte-removebg-preview.png" alt="Icona 3">
                <p>OFFERTE</p>
            </a>
        </li>
        <li>
            <a href="pages/esperienze">
                <img src="../../../assets/icone/itinerari-removebg-preview.png" alt="Icona 3">
                <p>ITINERARI<br />
                DIDATTICI</p>
            </a>
        </li>
        <li>
            <a href="pages/mappe">
                <img src="../../../assets/icone/mappe-removebg-preview.png" alt="Icona 3">
                <p>MAPPE<br />
                TEMATICHE</p>
            </a>
        </li>
        <li>
            <a href="pages/calabria-for-kids">
                <img src="../../../assets/icone/kids-removebg-preview.png" alt="Icona 3">
                <p>CALABRIA<br />
                FOR KIDST</p>
            </a>
        </li>
    </ul>   
</div>