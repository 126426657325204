<div  id="containerVideo" class="container">
    <video autoplay loop [muted]="'muted'" plays-inline class="background-clip">
        <source src="../../../assets/videos/videoHome.mp4" type="video/mp4">
    </video>
    <div class="content">
        <h1>Calabria</h1>
        <h3>Natura e meraviglia</h3>
        <p><a href="#">Scopri</a></p>
    </div>
</div>
<div id="fixed-element" [ngClass]="[isFixed ? 'fixed': 'notFixed']">
    <app-sidenav></app-sidenav>
</div>

    <div class="noMobileText" style="text-align: center; margin-top: 30px;">
        <div class="textCenter">
            <p>
                Immagina di attraversare chilometri e chilometri di paesaggio incontaminato, a ritmo lento, in bici, a piedi, a <br />
                cavallo, e ascoltare il battito della natura in una dimensione più umana e sostenibile.<br />
                <br>
                Immagina di ritrovare tutto il benessere di cui hai bisogno respirando l’aria più pura d’Europa, camminando <br />
                tra boschi e foreste vetuste, abbracciando alberi monumentali ed esplorando geositi, patrimonio <br />
                dell’Umanità. Ma anche di tuffarti nel mare azzurro dello Jonio e del Tirreno, potendo scegliere tra 800 Km di <br />
                costa da togliere il fiato.<br />
                <br>
                Immagina di scoprire un mondo di storie, saperi e tradizioni millenarie custodite nei musei e nei siti <br />
                archeologici, nei borghi arroccati a segnare il paesaggio come note di uno spartito, assaporando ottimi vini e <br />
                cibi autentici in cui sono racchiusi tutti i sapori del Mediterraneo.<br />
                <br>
                Questa è Calabria, una terra sorprendente, un tessuto meraviglioso di cultura, natura e storia, un “giardino <br />
                segreto” che ti conquisterà.
            </p>
        </div>
    </div>

    <div class="mobileText" style="text-align: center !important; margin-top: 30px;">
        <div style="max-width: 400px; margin: 0 auto;">
            <p style="font-size: 16px;">
                Immagina di attraversare chilometri e chilometri di paesaggio incontaminato, a ritmo lento, in bici, a piedi, a
                cavallo, e ascoltare il battito della natura in una dimensione più umana e sostenibile.<br />
                <br>
                Immagina di ritrovare tutto il benessere di cui hai bisogno respirando l’aria più pura d’Europa, camminando
                tra boschi e foreste vetuste, abbracciando alberi monumentali ed esplorando geositi, patrimonio
                dell’Umanità. Ma anche di tuffarti nel mare azzurro dello Jonio e del Tirreno, potendo scegliere tra 800 Km di
                costa da togliere il fiato.<br />
                <br>
                Immagina di scoprire un mondo di storie, saperi e tradizioni millenarie custodite nei musei e nei siti <br />
                archeologici, nei borghi arroccati a segnare il paesaggio come note di uno spartito, assaporando ottimi vini e
                cibi autentici in cui sono racchiusi tutti i sapori del Mediterraneo.<br />
                <br>
                Questa è Calabria, una terra sorprendente, un tessuto meraviglioso di cultura, natura e storia, un “giardino
                segreto” che ti conquisterà.
            </p>
        </div>
    </div>
    <br>
    <br>
   <div class="home-card">
        <div class="row">
            <div class="col-md-4 col-12">
                <div class="row">
                    <div class="col one" id="one">
                        <div class="card one">
                            <div class="card-body">
                                <div class="content-card">
                                    <h1>Cammini<br />e outdoor</h1>
                                    <a (click)="goToCammini()">Scopri</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col four" id="four">
                        <div class="card four">
                            <div class="card-body">
                                <div class="content-card">
                                    <h1>Storia, arte e<br />spiritualità</h1>
                                    <a (click)="goToStoria()">Scopri</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 col-12">
                <div class="col two" id="two">
                    <div class="card two">
                        <div class="card-body">
                            <div class="content-card">
                                <h1>Natura e<br />benessere</h1>
                                <a (click)="goToNatura()">Scopri</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="row">
                    <div class="col three" id="three">
                        <div class="card three">
                            <div class="card-body">
                                <div class="content-card">
                                    <h1>Cicloturismo</h1>
                                    <a (click)="goToCicloturismo()">Scopri</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col six" id="six">
                        <div class="card six">
                            <div class="card-body">
                                <div class="content-card">
                                    <h1>Enogastronomia<br />e tradizioni</h1>
                                    <a (click)="goToGusto()">Scopri</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<br>
<br>
<app-mappe-tematiche></app-mappe-tematiche>
<br>
<br>
<div class="noMobileNews">
    <div class="newsletter">
        <div class="row">
            <div class="col">
                <h2>Rimani in contatto con noi <br />
                    iscriviti alla nostra newsletter ></h2>
            </div>
            <div class="col">
                <h3>Entra nella community di Zampino Viaggi!</h3>
                <p>Clicca sul pulsante qui sotto, compila il form e riceverai le nostre<br />
                    novità su proposte, esperienze, eventi e qualche curiosità.</p>  
            </div>
        </div>
        <div class="row">
            <a href="#" class="btn btn-primary signinNewsButton">Scopri</a>
        </div>
    </div>
</div>


<div class="mobileNews">
    <div class="newsletter">
        <div class="row">
            <div class="col">
                <h2>Rimani in contatto con noi<br />iscriviti alla nostra newsletter</h2>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-sm-12">
                <h3>Entra nella community di Zampino Viaggi!</h3>
                <br>
                <p>Clicca sul pulsante qui sotto, compila il form e riceverai le nostre<br />novità su proposte, esperienze, eventi e qualche curiosità.</p>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <a href="#" class="btn btn-primary signinNewsButton">Scopri</a>
            </div>
        </div>
    </div>
</div>

