import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ModuleCardComponent } from './module-card/module-card.component';
import { RouterModule } from '@angular/router';
import { SidenavComponent } from './sidenav/sidenav.component';
import { MappeTematicheComponent } from './mappe-tematiche/mappe-tematiche.component';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ModuleCardComponent,
    SidenavComponent,
    MappeTematicheComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SidebarModule,
    ButtonModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ModuleCardComponent,
    SidenavComponent,
    MappeTematicheComponent
  ]
})
export class LayoutModule { }
