<div class="row cards">
    <div class="col">
        <div class="card card-image"
  style="background-image: url({{image}});">
  <div class="overlay"></div>

  <!-- Content -->
  <div class="card-content py-5 px-4">
    <div>
      <h5 class="head-text"><i class="fas fa-chart-pie"></i> {{nome}}</h5>
      <p>{{description}}</p>
      <button class="btn btn-primary detail-button">Scopri</button>
    </div>
  </div>

</div>
    </div>
    <div class="col">
        <div class="card card-image"
        style="background-image: url(https://mdbootstrap.com/img/Photos/Horizontal/Work/4-col/img%20%2814%29.webp);">
        <div class="overlay"></div>
      
        <!-- Content -->
        <div class="card-content py-5 px-4">
          <div>
            <h5 class="head-text"><i class="fas fa-chart-pie"></i> Messico</h5>
            <button class="btn btn-primary detail-button">Scopri</button>
          </div>
        </div>
      
      </div>
    </div>
    <div class="col">
        <div class="card card-image"
        style="background-image: url(https://mdbootstrap.com/img/Photos/Horizontal/Work/4-col/img%20%2814%29.webp);">
        <div class="overlay"></div>
      
        <!-- Content -->
        <div class="card-content py-5 px-4">
          <div>
            <h5 class="head-text"><i class="fas fa-chart-pie"></i> Crociera Nel Mediterraneo Occedentale</h5>
            <button class="btn btn-primary detail-button">Scopri</button>
          </div>
        </div>
      
      </div>
    </div>
    <div class="col">
        <div class="card card-image"
        style="background-image: url(https://mdbootstrap.com/img/Photos/Horizontal/Work/4-col/img%20%2814%29.webp);">
        <div class="overlay"></div>
      
        <!-- Content -->
        <div class="card-content py-5 px-4">
          <div>
            <h5 class="head-text"><i class="fas fa-chart-pie"></i> Nord Europa In Crociera</h5>
            <button class="btn btn-primary detail-button">Scopri</button>
          </div>
        </div>
      
      </div>
    </div>
</div>
