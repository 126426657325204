import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mappe-tematiche',
  templateUrl: './mappe-tematiche.component.html',
  styleUrls: ['./mappe-tematiche.component.css']
})
export class MappeTematicheComponent implements OnInit {
  
  image: any = '../../../assets/images/mappa1.jpg';
  cardImage: any = "../../../assets/images/01_Bosco-Parco-Serre.jpg";
  titolo: string = "Parchi e Riserve";
  parchiNav:boolean = true;
  sitiNav:boolean = false;
  borghiNav:boolean = false;
  museiNav:boolean = false;
  luoghiNav:boolean = false;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  parchi(){
    this.image = "../../../assets/images/mappa1.jpg";
    this.cardImage = "../../../assets/images/01_Bosco-Parco-Serre.jpg";
    this.titolo = "Parchi e Riserve";
    this.parchiNav = true;
    this.sitiNav = false;
    this.borghiNav = false;
    this.museiNav = false;
    this.luoghiNav = false;
  }

  siti(){
    this.image = "../../../assets/images/mappa2.jpg";
    this.cardImage = "../../../assets/images/02b_Unesco-Aspromonte_Pietra-Cappa-new.jpg";
    this.titolo = "Siti UNESCO";
    this.parchiNav = false;
    this.sitiNav = true;
    this.borghiNav = false;
    this.museiNav = false;
    this.luoghiNav = false;
  }

  borghi(){
    this.image = "../../../assets/images/mappa3.jpg";
    this.cardImage = "../../../assets/images/02c_Borghi_Santa-Severina-new.jpg";
    this.titolo = "Borghi storici";
    this.parchiNav = false;
    this.sitiNav = false;
    this.borghiNav = true;
    this.museiNav = false;
    this.luoghiNav = false;
  }

  musei(){
    this.image = "../../../assets/images/mappa4.jpg";
    this.cardImage = "../../../assets/images/02d_Musei_Sala-Bronzi-RC-new.jpg";
    this.titolo = "Musei e siti archeologici";
    this.parchiNav = false;
    this.sitiNav = false;
    this.borghiNav = false;
    this.museiNav = true;
    this.luoghiNav = false;
  }

  luoghi(){
    this.image = "../../../assets/images/mappa5.jpg";
    this.cardImage = "../../../assets/images/02e_Culto_Sant-Adriano_San-Demetrio-Corone.jpg";
    this.titolo = "Luoghi d’arte e spiritualità";
    this.parchiNav = false;
    this.sitiNav = false;
    this.borghiNav = false;
    this.museiNav = false;
    this.luoghiNav = true;
  }

  goTo(){
    if(this.parchiNav){
      this.router.navigate(['pages/parchi-e-riserve']);
    } else {
      if(this.sitiNav){
        this.router.navigate(['pages/siti-unesco']);
      } else {
        if(this.borghiNav){
          this.router.navigate(['pages/borghi-storici']);
        } else {
          if(this.museiNav){
            this.router.navigate(['pages/musei-siti-archeologici']);
          } else {
            if(this.luoghiNav){
              this.router.navigate(['pages/luohi-arte-storia-spiritualita']);
            }
          }
        }
      }
    }
    
  }

}
