<div class="map-card">
    <div class="row">
        <div class="col- col-xl-4">
            <h1>Segui le tue passioni<br />
                e scopri la Calabria</h1>
            <br>
            <p>
                <a (click)="parchi()">Parchi e Riserve</a> | <a (click)="siti()">Siti UNESCO</a> | <a (click)="borghi()">Borghi storici</a> <br />
                <a (click)="musei()">Musei e siti archeologici</a> | <a (click)="luoghi()">Luoghi d’arte e spiritualità</a>
            </p>
         
            <div class="card mapDetail">
                <img class="card-img-top" [src]="cardImage" alt="Card image cap">
                <div class="card-body">
                    <div class="card-content">
                        <div class="row">
                            <div class="col-12 col-xl-6">
                                <h3 class="classLine align-middle">{{titolo}}</h3>
                            </div>
                            <div class="col-12 col-xl-6">
                                <a (click)="goTo()" class="btn btn-primary mapDetailButton">Scopri</a>
                            </div>
                        </div>  
                    </div>
                  </div>
            </div>
        </div>
        <div class="col-10 col-xl-4">
            <img class="mapImage" alt="mappe tematiche" [src]="image">
        </div>
    </div>
</div>