import { Component, OnInit } from '@angular/core';
import { TestcardService } from 'src/app/shared/services/testcard.service';

@Component({
  selector: 'app-module-card',
  templateUrl: './module-card.component.html',
  styleUrls: ['./module-card.component.css']
})
export class ModuleCardComponent implements OnInit {

  image: any;
  nome: any;
  description: any;

  constructor(
    private _cardService: TestcardService
  ) { }

  ngOnInit(): void {

    this._cardService.getContent().subscribe(
      resp => {
        console.log(resp.data[0].attributes);
        this.nome = resp.data[0].attributes.nome;
        this.description = resp.data[0].attributes.description[0].children[0].text;
        this.image = 'http://localhost:1337' + resp.data[0].attributes.image.data.attributes.url;
      }
    )
  }

}
