<!-- <footer class="page-footer font-small blue pt-4">
    <div class="container-fluid text-center text-md-left">
      <div class="row">
        <div class="col-md-6 mt-md-0 mt-3">
          <h5 class="text-uppercase">Zampino Viaggi</h5>
          <p>Here you can use rows and columns to organize your footer content.</p>
        </div>
        <hr class="clearfix w-100 d-md-none pb-3">
        <div class="col-md-3 mb-md-0 mb-3">
          <h5 class="text-uppercase">Link Utili</h5>
          <ul class="list-unstyled">
            <li>
              <a href="#!">Link 1</a>
            </li>
            <li>
              <a href="#!">Link 2</a>
            </li>
            <li>
              <a href="#!">Link 3</a>
            </li>
            <li>
              <a href="#!">Link 4</a>
            </li>
          </ul>
        </div>
        <div class="col-md-3 mb-md-0 mb-3">
          <h5 class="text-uppercase">Contatti</h5>
          <ul class="list-unstyled">
            <li>
                <span><i class="bi bi-telephone"></i> 3881234567</span>
            </li>
            <li>
                <span><i class="bi bi-envelope"></i> test@example.com</span>
            </li>
          </ul>
          <h5 class="text-uppercase">Social</h5>
          <ul class="list-unstyled">
            <li>
              <a href="#!">Link 1</a>
            </li>
            <li>
              <a href="#!">Link 2</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
</footer> -->

<footer class="noMobile">
  <br>
    <div class="row">
      <div class="col">
        <img alt="logo zampino footer" src="../../../assets/images/footerLogo.png">
      </div>  
      <div class="col">
        <p>
          Ragione sociale e sede legale: <br />
          ZAMPINO VIAGGI <br />
          DI ZAMPINO GIUSEPPE GIOVANNI <br />
          Via Nazionale, 279-281 <br />
          Corigliano-Rossano (CS) <br />
          P.IVA: 02452890789
        </p>
      </div>  
      <div class="col">
        <p>
          Contatti:<br />
          info@zampinoviaggi.it<br />
          Tel. 0983 018001<br />
          <span><i class="bi bi-whatsapp"></i></span>
          <span><i class="bi bi-facebook"></i></span>
          <span><i class="bi bi-instagram"></i></span>
          <span><i class="bi bi-youtube"></i></span>
        </p>
      </div>  
      <div class="col-2">
        <ul class="list-unstyled">
          <li>
            <a href="#!">Chi siamo</a>
          </li>
          <li>
            <a href="#!">Dove siamo</a>
          </li>
          <li>
            <a href="#!">Download</a>
          </li>
          <li>
            <a href="#!">Link utili</a>
          </li>
          <li>
            <a href="#!">Credits</a>
          </li>
        </ul>
      </div>  
      <div class="col-2">
        <ul class="list-unstyled">
          <li>
            <a href="#!">Cookie Policy</a>
          </li>
          <li>
            <a href="#!">Policy Privacy</a>
          </li>
          <li>
            <a href="#!">Note legali</a>
          </li>
          <li>
            <a href="#!">Area operatori</a>
          </li>
        </ul>
      </div>  
    </div>
    <div class="row">
      <div class="col-5">
        <p>
          Progetto realizzato con il Contributo della Regione Calabria<br />
          Fondo Unico per la concessione di agevolazioni, sovvenzioni, incentivi e<br />
          contributi di qualsiasi genere alle imprese (articoli 19,30,31,41,48 del Dlgs<br />
          112/98 DGR n. 133 del 15 aprile 2021)<br />
          AVVISO PUBBLICO: Interventi a supporto dell’intermediazione turistica per<br />
          il rilancio dell’offerta turistica regionale
        </p>
      </div>
      <div class="col-1">
        <img alt="immagine regione calabria" src="../../../assets/images/1.PNG">
      </div>
      <div class="col-1">
        <img alt="immagine repubblica italiana" src="../../../assets/images/2.PNG">
      </div>
      <div class="col">
        <span (click)="scrollToTop()"><i class="bi bi-chevron-up goUp"></i></span>
      </div>
    </div>
</footer>

<footer class="mobile">
    <div class="row">
      <div class="col-12 col-md-6">
        <img alt="logo zampino footer" src="../../../assets/images/footerLogo.png">
      </div>
      <div class="col-12 col-md-6">
        <p>
          Ragione sociale e sede legale: <br />
          ZAMPINO VIAGGI <br />
          DI ZAMPINO GIUSEPPE GIOVANNI <br />
          Via Nazionale, 279-281 <br />
          Corigliano-Rossano (CS) <br />
          P.IVA: 02452890789
        </p>
      </div>
      <div class="col-12">
        <p>
          Contatti:<br />
          info@zampinoviaggi.it<br />
          Tel. 0983 018001<br />
          <span><i class="bi bi-whatsapp"></i></span>
          <span><i class="bi bi-facebook"></i></span>
          <span><i class="bi bi-instagram"></i></span>
          <span><i class="bi bi-youtube"></i></span>
        </p>
      </div>
      <div class="col-6 col-md-2">
        <ul class="list-unstyled">
          <li>
            <a href="#!">Chi siamo</a>
          </li>
          <li>
            <a href="#!">Dove siamo</a>
          </li>
          <li>
            <a href="#!">Download</a>
          </li>
          <li>
            <a href="#!">Link utili</a>
          </li>
          <li>
            <a href="#!">Credits</a>
          </li>
        </ul>
      </div>
      <div class="col-6 col-md-2">
        <ul class="list-unstyled">
          <li>
            <a href="#!">Cookie Policy</a>
          </li>
          <li>
            <a href="#!">Policy Privacy</a>
          </li>
          <li>
            <a href="#!">Note legali</a>
          </li>
          <li>
            <a href="#!">Area operatori</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-5">
        <p>
          Progetto realizzato con il Contributo della Regione Calabria<br />
          Fondo Unico per la concessione di agevolazioni, sovvenzioni, incentivi e<br />
          contributi di qualsiasi genere alle imprese (articoli 19,30,31,41,48 del Dlgs<br />
          112/98 DGR n. 133 del 15 aprile 2021)<br />
          AVVISO PUBBLICO: Interventi a supporto dell’intermediazione turistica per<br />
          il rilancio dell’offerta turistica regionale
        </p>
      </div>
      <div class="col-6 col-md-1">
        <img alt="immagine regione calabria" src="../../../assets/images/1.PNG">
      </div>
      <div class="col-6 col-md-1">
        <img alt="immagine repubblica italiana" src="../../../assets/images/2.PNG">
      </div>
      <div class="col-12 col-md-1">
        <span (click)="scrollToTop()"><i class="bi bi-chevron-up goUp"></i></span>
      </div>
    </div>
</footer>