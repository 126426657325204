import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConfigService } from '../config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TestcardService {

  readonly strapiApiUrl;
  private _environmentObj = environment;

  constructor(
    protected _configService: ConfigService,
    protected _http: HttpClient) 
  { 
    this.strapiApiUrl = _configService.strapiApiUrl;
  }

  getContent() {
    const url = this.strapiApiUrl + '/tests?populate=*';
    const auth_token = 'a744a8102a3d630330735f980acce8fedd41f2a26800143d5509bc948092d3d06a724f6357ec3ce1787264d07df730c14f2b8d35c8d2f080435d3a97b6d9a10b0469bba324779b96c9e45c35f3134abbb236e67a527f8510376323b3e2f70e7d3182621ea9adb7c5b42855a8384c8351df718e5a8c52f7bc10b1a2702593c5d0'
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`
    })
    return this._http.get<any>(url, { headers: headers })
  }
}
