import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  sidebarVisible: boolean = false;

  toggleDropdown(event: any): void {
    event.currentTarget.classList.toggle("active");
    var dropdownContent = event.currentTarget.nextElementSibling;
    if (dropdownContent.style.display === "block") {
      dropdownContent.style.display = "none";
    } else {
      dropdownContent.style.display = "block";
    }
  }

  isMenuOpen: boolean = false;

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if(document.querySelector('.menu__box') != null || document.querySelector('.menu__toggle') != null){
      const menuBox = document.querySelector('.menu__box');
      const menuToggle = document.getElementById('menu__toggle');
  
      if (menuBox?.contains(event.target as Node) || menuToggle?.contains(event.target as Node)) {
        // Il click è avvenuto all'interno della sidenav o sul toggle, quindi non fare nulla
        return;
      } else {
        // Il click è avvenuto all'esterno della sidenav, quindi chiudi la sidenav
        this.isMenuOpen = false;
      }
    }
    
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }


  constructor() { }

  ngOnInit(): void {
  }

}
