import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuloRicercaComponent } from './modulo-ricerca/modulo-ricerca.component';



@NgModule({
  declarations: [
    ModuloRicercaComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ModuloRicercaComponent
  ]
})
export class SearchModule { }
