import { Component, OnInit, HostListener} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  isFixed = false;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const div2Offset = document.getElementById('containerVideo')?.offsetTop || 0;
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || 0;

    if (scrollPosition > div2Offset) {
      this.isFixed = true;
    } else {
      this.isFixed = false;
    }
  }

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  goToCammini(){
    this.router.navigate(['pages/cammini-outdoor']);
  }

  goToCicloturismo(){
    this.router.navigate(['pages/cicloturismo']);
  }

  goToNatura(){
    this.router.navigate(['pages/natura-e-benessere']);
  }

  goToStoria(){
    this.router.navigate(['pages/storia-arte-e-spiritualita']);
  }

  goToGusto(){
    this.router.navigate(['pages/gusto-e-tradizioni']);
  }


}
