  <div class="row toolsNav">

          <span><i class="bi bi-house-door-fill" routerLink="pages/home"></i></span>
          <span><i class="bi bi-whatsapp"></i></span>
          <a aria-label="seguici su facebook" target="_blank" href="https://www.facebook.com/zampinoviaggi/?locale=it_IT"><span><i class="bi bi-facebook"></i></span></a>
          <a aria-label="seguici su instagram" target="_blank" href="https://www.instagram.com/zampino_viaggi/"><span><i class="bi bi-instagram"></i></span></a>
          <a aria-label="seguici su youtube" target="_blank" href="https://www.youtube.com/@zampino_viaggi"><span><i class="bi bi-youtube"></i></span></a>
          <!-- <span><i class="bi bi-search"></i></span> -->
 
  </div>
  <div class="noMobile">
    <div class="row headerNav">
      <div class="col headerImage">
        <img  src="../../../assets/images/zampinoLogo.png" style="width: 185px;" alt="logo" routerLink="pages/home" routerLinkActive="active">
      </div>
      <div class="col">
        <!-- <h5 class="headerTab">SCOPRI LA CALABRIA</h5> -->
        <div class="dropdown">
          <h5 class="headerTab" routerLink="pages/scopri-la-calabria" routerLinkActive="active" id="scopriLaCalabria">SCOPRI LA CALABRIA</h5>
          <button aria-labelledby="scopriLaCalabria"  class="btn btn-secondary dropdown-toggle dropButton1" type="button" id="dropdownMenuScopriLaCalabria" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          </button>
          <div class="dropdown-menu" aria-labelledby="Menu scopri la calabria">
            <a class="dropdown-item" routerLink="pages/parchi-e-riserve">Parchi e Riserve</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="pages/siti-unesco">Siti UNESCO</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="pages/borghi-storici">Borghi storici</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="pages/musei-siti-archeologici">Musei e siti archeologici</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="pages/luohi-arte-storia-spiritualita">Luoghi d’arte, storia e 
              spiritualità</a>
            <div class="dropdown-divider"></div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="dropdown">
          <h5 class="headerTab" routerLink="pages/esperienze" routerLinkActive="active" id="esperienzeEItinerari">ESPERIENZE E ITINERARI</h5>
          <button aria-labelledby="esperienzeEItinerari" class="btn btn-secondary dropdown-toggle dropButton" style="margin-left: 29px;"  type="button" id="dropdownMenuEsperienzeEItinerari" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          </button>
          <div class="dropdown-menu" aria-labelledby="Menu esperienze e itinerari">
            <a class="dropdown-item" routerLink="pages/cammini-outdoor">Cammini e outdoor</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="pages/cicloturismo">Cicloturismo</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="pages/natura-e-benessere">Natura e benessere</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="pages/storia-arte-e-spiritualita">Storia, arte e spiritualità</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="pages/gusto-e-tradizioni">Gusto e tradizioni</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="pages/calabria-for-kids">Calabria for kids</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="pages/itinerari-didattici">Itinerari didattici</a>
            <div class="dropdown-divider"></div>
          </div>
        </div>
      </div>
      <div class="col">
        <h5 class="headerTab" routerLink="pages/eventi-news" routerLinkActive="active">EVENTI E NEWS</h5>
      </div>
      <div class="col">
        <a href="https://zampinoviaggi.com/"><h5 class="headerTab">ALTRE DESTINAZIONI</h5></a>
      </div>
     <!--  <div class="col">
        <h5 class="headerTab" routerLink="pages/login" routerLinkActive="active" id="login">LOGIN</h5>
      </div> -->
    </div>
  </div>


<div class="mobile">

  
    <p-sidebar [(visible)]="sidebarVisible">        
      <ul>
       <li> 
         <a class="menu__item" href="pages/scopri-la-calabria">SCOPRI LA CALABRIA</a>  
          <a class="dropdown-btn" (click)="toggleDropdown($event)"><i class="bi bi-caret-down-fill"></i></a>
         <div class="dropdown-container">
           <ul>
             <li class="subli"><a href="pages/parchi-e-riserve">Parchi e Riserve</a></li>
             <li class="subli"><a href="pages/siti-unesco">Siti UNESCO</a></li>
             <li class="subli"><a href="pages/borghi-storici">Borghi storici</a></li>
             <li class="subli"><a href="pages/musei-siti-archeologici">Musei e siti archeologici</a></li>
             <li class="subli"><a href="pages/luohi-arte-storia-spiritualita">Luoghi d’arte, storia e spiritualità</a></li>
           </ul>
         </div>
       </li>      
       <li> 
         <a class="menu__item" href="pages/esperienze">ESPERIENZE E ITINERARI</a>  
          <a class="dropdown-btn" (click)="toggleDropdown($event)"><i class="bi bi-caret-down-fill"></i></a>
         <div class="dropdown-container">
           <ul>
             <li class="subli"><a href="pages/cammini-outdoor">Cammini e outdoor</a></li>
             <li class="subli"><a href="pages/cicloturismo">Cicloturismo</a></li>
             <li class="subli"><a href="pages/natura-e-benessere">Natura e benessere</a></li>
             <li class="subli"><a href="pages/storia-arte-e-spiritualita">Storia, arte e spiritualità</a></li>
             <li class="subli"><a href="pages/gusto-e-tradizioni">Gusto e tradizioni</a></li>
             <li class="subli"><a href="pages/calabria-for-kids">Calabria for kids</a></li>
             <li class="subli"><a href="pages/itinerari-didattici">Itinerari didattici</a></li>
           </ul>
         </div>
       </li>      
         <li><a class="menu__item" href="pages/eventi-news">EVENTI E NEWS</a></li>
         <li><a class="menu__item" href="https://zampinoviaggi.com/">ALTRE DESTINAZIONI</a></li>
         <li><a class="menu__item" href="pages/info">INFO</a></li>
         <li><a class="menu__item" href="pages/chi-siamo">CHI SIAMO</a></li>
         <li><a class="menu__item" href="pages/servizi">SERVIZI</a></li>
         <li><a class="menu__item" href="pages/offerte">OFFERTE</a></li>
         <li><a class="menu__item" href="pages/esperienze">ITINERARI DIDATTICI</a></li>
         <li><a class="menu__item" href="pages/mappe">MAPPE TEMATICHE</a></li>
         <li><a class="menu__item" href="pages/calabria-for-kids">CALABRIA FOR KIDS</a></li>
         
       </ul>
    </p-sidebar>
<!--     <p-button (click)="sidebarVisible = true" icon="bi bi-list"></p-button>
 -->    <input style="display: none;" id="menu__toggle" type="checkbox" [checked]="isMenuOpen" (click)="sidebarVisible = true"/>


  <div class="hamburger-menu">
<!--     <input id="menu__toggle" type="checkbox" [checked]="isMenuOpen" (click)="toggleMenu()"/>
 -->    <label class="menu__btn" for="menu__toggle">
      <span></span>
    </label>

    <ul class="menu__box" [class.open]="isMenuOpen">
     <!--  <li><a class="menu__item" href="pages/scopri-la-calabria">SCOPRI LA CALABRIA</a></li> -->
    <li> 
      <a class="menu__item" href="pages/scopri-la-calabria">SCOPRI LA CALABRIA</a>  
       <a class="dropdown-btn" (click)="toggleDropdown($event)"><i class="bi bi-caret-down-fill"></i></a>
      <div class="dropdown-container">
        <ul>
          <li class="subli"><a href="pages/parchi-e-riserve">Parchi e Riserve</a></li>
          <li class="subli"><a href="pages/siti-unesco">Siti UNESCO</a></li>
          <li class="subli"><a href="pages/borghi-storici">Borghi storici</a></li>
          <li class="subli"><a href="pages/musei-siti-archeologici">Musei e siti archeologici</a></li>
          <li class="subli"><a href="pages/luohi-arte-storia-spiritualita">Luoghi d’arte, storia e spiritualità</a></li>
        </ul>
      </div>
    </li>      
    <li> 
      <a class="menu__item" href="pages/esperienze">ESPERIENZE E ITINERARI</a>  
       <a class="dropdown-btn" (click)="toggleDropdown($event)"><i class="bi bi-caret-down-fill"></i></a>
      <div class="dropdown-container">
        <ul>
          <li class="subli"><a href="pages/cammini-outdoor">Cammini e outdoor</a></li>
          <li class="subli"><a href="pages/cicloturismo">Cicloturismo</a></li>
          <li class="subli"><a href="pages/natura-e-benessere">Natura e benessere</a></li>
          <li class="subli"><a href="pages/storia-arte-e-spiritualita">Storia, arte e spiritualità</a></li>
          <li class="subli"><a href="pages/gusto-e-tradizioni">Gusto e tradizioni</a></li>
          <li class="subli"><a href="pages/calabria-for-kids">Calabria for kids</a></li>
          <li class="subli"><a href="pages/itinerari-didattici">Itinerari didattici</a></li>
        </ul>
      </div>
    </li>      
      <li><a class="menu__item" href="pages/eventi-news">EVENTI E NEWS</a></li>
      <li><a class="menu__item" href="https://zampinoviaggi.com/">ALTRE DESTINAZIONI</a></li>
      <li><a class="menu__item" href="pages/info">INFO</a></li>
      <li><a class="menu__item" href="pages/chi-siamo">CHI SIAMO</a></li>
      <li><a class="menu__item" href="pages/servizi">SERVIZI</a></li>
      <li><a class="menu__item" href="pages/offerte">OFFERTE</a></li>
      <li><a class="menu__item" href="pages/esperienze">ITINERARI DIDATTICI</a></li>
      <li><a class="menu__item" href="pages/mappe">MAPPE TEMATICHE</a></li>
      <li><a class="menu__item" href="pages/calabria-for-kids">CALABRIA FOR KIDS</a></li>
      
    </ul>
  </div>
  <div class="row">
    <div class="col">
      <div class="headerImage">
        <img  src="../../../assets/images/zampinoLogo.png" style="width: 185px;" alt="logo" routerLink="pages/home" routerLinkActive="active">
      </div>
    </div>
    <div class="col">
    <!--   <a><button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <i class="bi bi-list burgerIcon"></i>
      </button></a> -->
    </div>
  </div>
  <br>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="headerNav">
        <li>
          <div class="dropdown">
            <h5 class="headerTab" routerLink="pages/scopri-la-calabria" routerLinkActive="active" id="scopriLaCalabria">SCOPRI LA CALABRIA</h5>
            <button aria-labelledby="scopriLaCalabria"  class="btn btn-secondary dropdown-toggle dropButton1" type="button" id="dropdownMenuScopriLaCalabria" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            </button>
            <div class="dropdown-menu" aria-labelledby="Menu scopri la calabria">
              <a class="dropdown-item" routerLink="pages/parchi-e-riserve">Parchi e Riserve</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="pages/siti-unesco">Siti UNESCO</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="pages/borghi-storici">Borghi storici</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="pages/musei-siti-archeologici">Musei e siti archeologici</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="pages/luohi-arte-storia-spiritualita">Luoghi d’arte, storia e 
                spiritualità</a>
              <div class="dropdown-divider"></div>
            </div>
          </div>
        </li>
        <li>
          <div class="dropdown">
            <h5 class="headerTab" routerLink="pages/esperienze" routerLinkActive="active" id="esperienzeEItinerari">ESPERIENZE E ITINERARI</h5>
            <button aria-labelledby="esperienzeEItinerari" class="btn btn-secondary dropdown-toggle dropButton"  type="button" id="dropdownMenuEsperienzeEItinerari" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            </button>
            <div class="dropdown-menu" aria-labelledby="Menu esperienze e itinerari">
              <a class="dropdown-item" href="#">Cammini e outdoor</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Cicloturismo</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Natura e benessere</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Storia, arte e spiritualità</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Gusto e tradizioni</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Calabria for kids</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Itinerari didattici</a>
              <div class="dropdown-divider"></div>
            </div>
          </div>
        </li>
        <li>
          <h5 class="headerTab">EVENTI E NEWS</h5>
        </li>
        <li>
          <a href="https://zampinoviaggi.com/"><h5 class="headerTab">ALTRE DESTINAZIONI</h5></a>
        </li>
        <li>
          <h5 class="headerTab" routerLink="pages/login" routerLinkActive="active" id="login">LOGIN</h5>
        </li>
      </ul>
    </div>
  
</div>
 